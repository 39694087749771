import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/menu.scss";
import {
  FaGraduationCap,
  FaTools,
  FaFileAlt,
  FaEnvelope,
} from "react-icons/fa";

const Menu = ({ despliega, setDespliega }) => {
  const handleClick = () => {
    document.getElementsByClassName("content")[0].scrollTop = 0;
    setDespliega(2);
  };
  return (
    <div
      className={`menu ${despliega === 1 && "show"} ${
        despliega === 2 && "hide"
      }`}
    >
      <ul>
        <li onClick={handleClick}>
          <Link to="/">
            <FaGraduationCap />
            <span>Sobre mi</span>
          </Link>
        </li>
        <li onClick={handleClick}>
          <Link to="/projects">
            <FaTools />
            <span>Proyectos</span>
          </Link>
        </li>
        <li onClick={handleClick}>
          <Link to="/resume">
            <FaFileAlt />
            <span>Curriculum</span>
          </Link>
        </li>
        <li onClick={handleClick}>
          <Link to="/contact">
            <FaEnvelope />
            <span>Contacto</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
