const jsonTraining = [
  {
    range: {
      from: "1995",
      to: "2001",
    },
    position: "Ingeniería en Computación e Informática",
    company: "Universidad Nacional Andrés Bello",
  },
  {
    range: {
      from: "1988",
      to: "1991",
    },
    position: "Enseñanza Media",
    company: "Junior College (Arica)",
  },
];

export default jsonTraining;
