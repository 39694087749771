import "../../../../styles/resume.scss";
import photo from "../../../../images/foto.jpg";
import ResumeItem from "./ResumeItem";
import jsonJobs from "../../../../data/jobs";
import jsonTraining from "../../../../data/training";
import jsonCertifications from "../../../../data/certifications";

const Resume = () => {
  return (
    <div className="resume">
      <div className="rs-content">
        <div className="rs-section">
          <div className="rs-title">Curriculum</div>
          <div className="rs-description">
            Amplia capacidad de liderazgo de proyectos informáticos, así como
            también en la formación y capacitación de equipos. Vasta experiencia
            y formación como arquitecto de soluciones tecnológicas, pedagógico,
            didáctico en el traspaso de conocimientos al equipo y flexible a la
            adaptación y orientación del negocio. Habilidad intrínseca para
            trabajar en equipos multidisciplinarios, objetivos reales al corto
            plazo, resultados eficientes sin dejar que la presión sea un
            componente en la ecuación del éxito.
          </div>
        </div>
        <div className="rs-section">
          <div className="rs-sub-title">Experiencia Profesional</div>
          <div className="rs-detail">
            {jsonJobs.map((item, index) => (
              <ResumeItem key={index} item={item} />
            ))}
          </div>
          <div className="rs-sub-title">Formación</div>
          <div className="rs-detail">
            {jsonTraining.map((item, index) => (
              <ResumeItem key={index} item={item} />
            ))}
          </div>
          <div className="rs-sub-title">Certificaciones Recientes</div>
          <div className="rs-detail">
            {jsonCertifications.map((item, index) => (
              <ResumeItem key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
      <div className="rs-personal">
        <div className="rs-photo">
          <img src={photo} alt="" />
        </div>
        <div className="rs-name">
          <div className="rs-h1">Claudio Matus Alegría</div>
          <div className="rs-h2">Arquitecto de Soluciones Tecnológicas</div>
        </div>
        <div className="rs-section">
          <div className="rs-title">Información Personal</div>
          <div className="rs-form">
            <div className="rs-dato">
              <div className="rs-nombre">Rut</div>
              <div className="rs-valor">12.869.241-K</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Fec. Nacimiento</div>
              <div className="rs-valor">22/02/1975</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Nacionalidad</div>
              <div className="rs-valor">Chileno</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Estado Civil</div>
              <div className="rs-valor">Casado</div>
            </div>
          </div>
        </div>
        <div className="rs-section">
          <div className="rs-title">Datos de Contacto</div>
          <div className="rs-form">
            <div className="rs-dato">
              <div className="rs-nombre">e-mail</div>
              <div className="rs-valor">claudio@matus.cl</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Dirección</div>
              <div className="rs-valor">Viola García Torrejón 1698, Buin</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Teléfono</div>
              <div className="rs-valor">+569 9978 1640</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">LinkedIn</div>
              <div className="rs-valor">in/claudiomatus</div>
            </div>
            <div className="rs-dato">
              <div className="rs-nombre">Web</div>
              <div className="rs-valor">https://portfolio.cladveloper.cl</div>
            </div>
          </div>
        </div>
        <div className="rs-section">
          <div className="rs-title">Habilidades</div>
          <div className="rs-list">
            <ul>
              <li>Liderazgo de equipos</li>
              <li>Formación y capacitación de personal</li>
              <li>Integración de tecnología (SW+HW)</li>
              <li>Diseño y construcción de software</li>
              <li>Apoyo técnico a la gestión comercial</li>
              <li>Apoyo técnico a la gestión tecnológica</li>
              <li>Gestión de proyectos de tecnología</li>
            </ul>
          </div>
        </div>
        <div className="rs-section">
          <div className="rs-title">Conocimientos</div>
          <div className="rs-list">
            <ul>
              <li>Herramientas de desarrollo de software</li>
              <li>Periféricos de pago (Transbank)</li>
              <li>Integración telefonía IP</li>
              <li>Integración equipos AutoConsulta</li>
              <li>Integración equipos de filas (turnos)</li>
              <li>Monitoreo hardware a tiempo real</li>
              <li>Gestión de proyectos de tecnología</li>
              <li>Construcción de aplicaciones distribuidas</li>
              <li>Implementación de servicios SOAP y Rest</li>
            </ul>
          </div>
        </div>
        <div className="rs-section">
          <div className="rs-version">v.0.18 - 2021</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
