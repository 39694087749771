import React, { useState } from "react";
import "../../../../styles/about.scss";
import photo from "../../../../images/me.png";
import AboutStackType from "./AboutStackType";
import { useModal } from "../../../../hooks/useModal";
import AboutStackModal from "./AboutStackModal";

const About = () => {
  const [isOpenStackModal, openStackModal, closeStackModal] = useModal(false);
  const [stackItemData, setStackItemData] = useState({});
  return (
    <>
      <AboutStackModal
        isOpenStackModal={isOpenStackModal}
        closeStackModal={closeStackModal}
      >
        <h3>
          {stackItemData.icon}
          {stackItemData.name}
        </h3>
        <div className="scroll">{stackItemData.detail}</div>
      </AboutStackModal>
      <div className="about">
        <div className="title">Sobre mi...</div>
        <div className="description">
          <p>
            Ingeniero en Computación e Informática de la Universidad Andrés
            Bello, especializado en diseño y desarrollo de software tanto en
            frontend como en backend. Me he realizado profesionalmente como
            líder de equipos, poseo gran empatía con subalternos, pares y
            superiores. Me gusta estar al día en temas tecnológicos, por ello no
            me detengo en el aprendizaje.
          </p>
          <img src={photo} alt="" />
        </div>
        <div className="section">
          <div className="sub-title">Capacidades</div>
          <p>
            Me siento muy cómodo al liderar un equipo de desarrollo pero también
            poseo las capacidades como para realizar cualquier labor dentro del
            organigrama del área en el caso que se requiera. Normalmente aplico
            los 4 pasos de la delegación para asegurar un mejor resultado y
            entendimiento de la instrucción:
          </p>
          <div className="steps">
            <div className="steps-item">
              <div>1</div>
              <div>
                Yo hago, tú miras
                <br />
                (enseñanza)
              </div>
            </div>
            <div className="steps-item">
              <div>2</div>
              <div>
                Yo hago, tú haces
                <br />
                (hacemos juntos)
              </div>
            </div>
            <div className="steps-item">
              <div>3</div>
              <div>
                Yo miro, tú haces
                <br />
                (supervisión)
              </div>
            </div>
            <div className="steps-item">
              <div>4</div>
              <div>
                Tú haces
                <br />
                (instrucción)
              </div>
            </div>
          </div>
          <p>
            Adicionalmente me ha tocado formar equipos desde cero partiendo por
            la definición del cargo, búsqueda, selección, inducción y
            capacitación de sus integrantes. De igual forma llevar registro del
            desempeño y productividad de cada uno.
          </p>
        </div>
        <div className="section">
          <div className="sub-title">Tecnologías</div>
          <p>
            A continuación detallo las tecnologías con las que poseo experiencia
            y son de uso general en los proyectos que he abordado. Las segmento
            por tipo y puedes dar clic en cada una para visualizar un detalle de
            mi interacción con ellas.
          </p>
          <AboutStackType
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            type={"frontend"}
          />
          <AboutStackType
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            type="backend"
          />
          <AboutStackType
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            type="db"
          />
          <AboutStackType
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            type="tool"
          />
          <AboutStackType
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            type="so"
          />
        </div>
        <div className="section">
          <div className="sub-title">Aprendizaje</div>
          <p>
            En mi constante búsqueda de nuevos conocimientos, me encuentro
            aprendiendo otras tecnologías que estaré incorporando a mi stack, si
            bien es cierto las conozco y podría implementarlas, siento que aun
            debo adquirir experiencia a nivel de proyectos productivos con las
            siguientes tecnologías:
          </p>
          <AboutStackType type={"learning"} />
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export default About;
