import React, { useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import Nav from "../components/portfolio/Nav/Nav";
import Content from "../components/portfolio/Content/Content";

const PortfolioRouter = () => {
  const [despliega, setDespliega] = useState(2);
  return (
    <div className="portfolio">
      <Router>
        <Nav setDespliega={setDespliega} despliega={despliega} />
        <Content setDespliega={setDespliega} />
      </Router>
    </div>
  );
};

export default PortfolioRouter;
