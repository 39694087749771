import React, { useState, useEffect } from "react";
import Axios from "axios";

const ContactForm = () => {
  const [txtName, setName] = useState("Claudio Matus Alegría");
  const [txtEmail, setEmail] = useState("claudio@matus.cl");
  const [txtPhone, setPhone] = useState("+569 9978 1640");
  const [txtMessage, setMessage] = useState("Mensaje de pruebas N°1");
  const [isSending, setSending] = useState(false);

  const newMessage = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    document.getElementById("txtName").classList.remove("error");
    document.getElementById("txtEmail").classList.remove("error");
    document.getElementById("txtPhone").classList.remove("error");
    document.getElementById("txtMessage").classList.remove("error");
    document.getElementById("txtName").classList.add("normal");
    document.getElementById("txtEmail").classList.add("normal");
    document.getElementById("txtPhone").classList.add("normal");
    document.getElementById("txtMessage").classList.add("normal");
    setSending(false);
  };

  const validateEmail = (email) => {
    if (
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateFields = () => {
    document.getElementById("txtName").classList.remove("error");
    document.getElementById("txtEmail").classList.remove("error");
    document.getElementById("txtPhone").classList.remove("error");
    document.getElementById("txtMessage").classList.remove("error");
    let ret = true;
    if (txtName === "") {
      document.getElementById("txtName").classList.add("error");
      ret = false;
    }
    if (txtEmail === "") {
      document.getElementById("txtEmail").classList.add("error");
      ret = false;
    }
    if (!validateEmail(txtEmail)) {
      document.getElementById("txtEmail").classList.add("error");
      ret = false;
    }
    if (txtMessage === "") {
      document.getElementById("txtMessage").classList.add("error");
      ret = false;
    }
    return ret;
  };

  const handleSendMail = () => {
    if (validateFields()) {
      setSending(true);
      Axios.post("https://mail-service.polite.cl/send-mail", {
        to: "claudio@matus.cl",
        subject: `Mensaje enviado desde portafolio: ${txtName}`,
        body: `<p><b>De:</b> ${txtName}<br /><b>e-mail:</b> ${txtEmail}<br /><b>Teléfono:</b> ${txtPhone}<br /><br />${txtMessage}</p>`,
      })
        .then((response) => newMessage())
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  useEffect(() => {
    newMessage();
  }, []);

  return (
    <div className="body">
      <div className="form">
        <div className="field">
          <label>Nombre</label>
          <input
            type="text"
            value={txtName}
            onChange={(e) => setName(e.target.value)}
            id="txtName"
            autocomplete="off"
          />
        </div>
        <div className="field">
          <label>e-mail</label>
          <input
            type="text"
            value={txtEmail}
            onChange={(e) => setEmail(e.target.value)}
            id="txtEmail"
            autocomplete="off"
          />
        </div>
        <div className="field">
          <label>Teléfono/celular</label>
          <input
            type="text"
            value={txtPhone}
            onChange={(e) => setPhone(e.target.value)}
            id="txtPhone"
            autocomplete="off"
          />
        </div>
        <div className="field">
          <label>Mensaje</label>
          <textarea
            id="txtMessage"
            value={txtMessage}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="field center">
          {isSending ? (
            <button>
              <i className="fas fa-spinner rotate"></i>
              <span>Enviando...</span>
            </button>
          ) : (
            <button onClick={handleSendMail}>
              <i className="fas fa-paper-plane"></i>
              <span>Enviar</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
