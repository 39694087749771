import StackItem from "../Stack/StackItem";
import jsonTechnology from "../../../../data/stack.js";

const Stack = ({ setStackItemData, openStackModal, side }) => {
  return (
    <div className="stack-list">
      {jsonTechnology
        .filter((item) => item.side === side)
        .map((item) => (
          <StackItem
            setStackItemData={setStackItemData}
            openStackModal={openStackModal}
            key={item.id}
            item={item}
          />
        ))}
    </div>
  );
};

export default Stack;
