import React from "react";
import "../../../../styles/projects.scss";
import ProjectItem from "./ProjectItem";
import jsonProjects from "../../../../data/projects";

const Projects = () => {
  return (
    <div className="projects">
      <div className="title">Proyectos</div>
      <div className="description">
        <p>
          Menciono a continuación algunos de los proyectos donde me ha tocado
          diseñar, construír y desplegar junto al equipo técnico. Esta selección
          está basada en mi grado de partipación y las tecnologías que se
          utilizaron, ya que significaron un gran desafío no solo técnico sino
          que además de ingenio para llevarlos a cabo.
        </p>
      </div>
      {jsonProjects.map((item) => (
        <ProjectItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default Projects;
