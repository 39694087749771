import React from "react";
import ContactForm from "./ContactForm";
import "../../../../styles/contact.scss";

const Contact = () => {
  return (
    <div className="contact">
      <div className="title">Contacto</div>
      <div className="description">
        <p>
          Por mi parte, encantado de que me puedas contactar, si tienes alguna
          duda respecto a lo que has visto en mi portafolio, si requieres de mis
          servicios, profundizar en alguno de mis proyectos o concretar alguna
          entrevista, pon tus datos y tu mensaje en el formulario a
          continuación.
        </p>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact;
