import {
  SiBootstrap,
  SiCircleci,
  SiCsharp,
  SiCss3,
  SiDocker,
  SiElastic,
  SiFigma,
  SiFirebase,
  SiGit,
  SiGithub,
  SiGithubactions,
  SiHtml5,
  SiJavascript,
  SiJquery,
  SiLinux,
  SiMariadb,
  SiMicrosoftsqlserver,
  SiMysql,
  SiNodeDotJs,
  SiPhp,
  SiPostgresql,
  SiRaspberrypi,
  SiReact,
  SiSocketDotIo,
  SiVisualstudio,
  SiWindows,
  SiNextDotJs,
  SiPython,
  SiFlask,
  SiDjango,
  SiVueDotJs,
  SiNuxtDotJs,
  SiVuetify,
  SiMongodb,
  SiGraphql,
  SiApollographql,
  SiHeroku,
  SiKubernetes,
  SiGitlab,
  SiTypescript,
  SiSass,
  SiJest,
  SiWebpack,
  SiJson,
  SiSvelte,
  SiNginx,
  SiApache,
  SiGatsby,
} from "react-icons/si";
import { IoLogoPwa } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";

const jsonTechnology = [
  {
    id: 1,
    icon: <SiJavascript fill="#fade00" size="25" />,
    name: "Javascript",
    type: "language",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Es definitivamente mi lenguaje de programación favorito, me gusta su
        flexibilidad y lo rápido que se pueden lograr resultados. Lo uso
        aproximadamente hace más de 15 años y las nuevas versiones de su
        especificación (ES6 en adelante) siento que lo consagran aún más como un
        lenguaje de alto nivel.
        <br />
        <br />
        Sigo aprendiendo diariamente de este lenguaje, y he logrado extender su
        uso a otras funcionalidades distintas a la web, por poner algunos
        ejemplos: electrónica (Arduino), aplicaciones de escritorio (Electron) y
        ejecución de comandos de consola (sobre todo en linux).
      </p>
    ),
  },
  {
    id: 2,
    icon: <SiNodeDotJs fill="#009300" size="25" />,
    name: "node.js",
    type: "language",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Me interesó mucho aprender esta tecnología ya que podría extender el uso
        de mi lenguaje de programación preferido al backend, lo cual fue una
        buena noticia, me enteré de su existencia aproximadamente a finales del
        año 2013, reconozco que temí que fuese a ser una tecnología que no
        perdurara en el tiempo o sea algo inestable.
        <br />
        <br />
        En el año 2015 fui enviado a una charla dictada en dependencias de
        Samsung Chile donde un grupo de ingenieros brasileros presentaron un
        Würlitzer programado en node.js montado en una pantalla tácil de la
        marca y corriendo de forma local (la pantalla contaba con un mini
        sistema operativo de core Linux).
        <br />
        <br />
        Este evento reforzó mi interés en esta tecnología y comencé a utilizarlo
        de manera formal desde inicios del año 2016, capacitandome al respecto
        con cursos dictados en Lynda.com (hoy parte de LinkedIn), luego EDTeam y
        últimanente Udemy.
        <br />
        <br />
        Ultimamente no solo programo API Rest usando Express o interacciones con
        bases de datos para proveer un backend a una aplicación, también he
        logrado integraciones con domótica (Arduino principalmente), ejecución
        de comandos shell en sistemas operativos para levantamiento de
        servicios, por ejemplo montar desde cero un Droplet de Digital Ocean
        pasando por la instalación de software, configuración de DNS hasta
        dejarlo 100% operativo con URL web y su certificado SSL para HTTPS todo
        completamente desatendido.
      </p>
    ),
  },
  {
    id: 3,
    icon: <SiHtml5 fill="#e23900" size="25" />,
    name: "HTML5",
    type: "language",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Lo aprendí en mis últimos años de Universidad, sin ánimo de ser
        presumido fue muy sencillo, en su momento solicité un libro en la
        biblioteca, lo leí completo, ya sabía programar así que quise hacer mi
        primera página Web pero me faltaba algo más, interacción, dinamismo,
        validaciones, etc. (todos sabemos que HTML por si solo es potente pero
        requiere de Javascript para lograr lo que estaba echando de menos).
        <br />
        <br />
        Más adelante (finales del año 2012) se hablaba de la especificación
        HTML5, la cual rápidamente aprendí, pero inicialmente era difícil
        aplicarla ya que no todos los navegadores la interpretaban. Hoy en día
        eso es historia y es mi punto de partida al programar.
      </p>
    ),
  },
  {
    id: 4,
    icon: <SiCss3 fill="#277fcf" size="25" />,
    name: "css3",
    type: "language",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Esta tecnología la estudié una vez comencé a necesitar brindarle estilos
        a mis primeros desarrollos Web, inicialmente mediante libros como
        "Beginning HTML5 and CSS3 For Dummies", donde aprendí muchisimo para
        luego formalizar conocimientos mediante Lynda.com y otros libros.
        <br />
        <br />
        Con el tiempo fui implementándolo cada vez mas, aplicando buenas
        prácticas, pero reconozco que siempre hay cosas nuevas que día a día
        aprendo.
      </p>
    ),
  },
  {
    id: 5,
    icon: <SiCsharp fill="#9f0091" size="25" />,
    name: "c# (.net)",
    type: "language",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Por decisión corporativa de la empresa donde me encontraba trabajando en
        el año 2009, comencé a aprender formalmente .NET, ya conocía muy bien
        Visual Basic 6.0 y pasarme a VisualBasic.NET no fue difícil, pero la
        exigencia era aprender c# por lo que hice dos maestrías, la primera de
        aprendizaje ese mismo año y la segunda avanzada (focalizada a servicios
        web) el siguiente año.
        <br />
        <br />
        Profesionalmente me sirvió mucho en las siguientes empresas donde
        trabajé ya que eran los lenguajes excluyentes de desarrollo junto con
        SQL Server, lo utilicé desde su versión Framework 3.5 hasta la 4.7,
        luego de eso comencé a especializarme en node.js ya que habían
        exigencias de utilizar el mismo backend en distintas plataformas, por
        ello no continué utilizándolo.
        <br />
        <br />
        Estoy claro de la existencia de la actual versión .NET Core 5.0 que es
        multiplataforma, por ello en caso de ser necesario puedo continuar mi
        aprendizaje.
      </p>
    ),
  },
  {
    id: 6,
    icon: <SiVisualstudio fill="#8157b4" size="25" />,
    name: "VisualBasic",
    type: "language",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Este fue el segundo lenguaje que aprendí para utilizar de forma laboral
        (el primero fue Clipper), inicialmente la empresa donde trabajaba me
        envió a capacitación para la versión 3.0 (hablamos del año 1995), luego
        aprendí por mi cuenta la versión 4.0 cuya única diferencia era que podía
        trabajar en arquitecturas de 32 Bits, luego aprendí a generar
        componentes COM en Visual Basic 6.0 junto con ASP para la Web (lo
        encontraba tedioso por que no era el mismo lenguaje, era como un
        subconjunto).
        <br />
        <br />
        Ya en el año 2009 con más de 10 años de experiencia en el lenguaje (con
        una brecha de PHP en el camino) vino el cambio radical a VisualBasic.NET
        donde ahora podía programar por capas, orientado a objetos y para la Web
        sin cambio alguno en el código, esta etapa fue corta ya que
        corporativamente la exigencia era c#, pero al final de cuentas, logré
        dominar ambos.
      </p>
    ),
  },
  {
    id: 7,
    icon: <SiPhp fill="#7b60b0" size="25" />,
    name: "php",
    type: "language",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Cuando aprendí este lenguaje quise definitivamente llevar todos mis
        proyectos en él, lo aprendí en el año 2005, antes de eso programaba en
        ASP puro ya que tenía experiencia en Visual Basic. PHP me gustó por que
        podia programar en mi equipo con Windows y despues podía llevarlo a un
        servidor con Linux y corría de la misma forma, e incluso en un servidor
        Windows Server con IIS. Si le activaba cierto complemento podía incluso
        conectarlo a SQL Server, por lo que podía interactuar con el motor de
        base de datos corporativo.
        <br />
        <br />
        Programé en sus versiones 4.x y luego en sus versiones 5.x. Ya en el año
        2009, por decisión corporativa adoptamos .NET tanto VisualBasic como c#,
        pero continué aprendiendo sus frameworks como Zend, Slim y Synphony
        hasta mediados del año 2015 (si hubiese aprendido Laravel posiblemente
        aún lo utilice).
      </p>
    ),
  },
  {
    id: 8,
    icon: <SiReact fill="#5ccef8" size="25" />,
    name: "ReactJS",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Es una de las tecnologías que más recientemente aprendí, reconozco que
        me negaba a dejar Vanilla Javascript, ya que me brindaba más
        flexibilidad, pero aspectos como la seguridad, los estándares y la
        programación reactiva, me motivó a volcarme a su aprendizaje (y también
        por que me vi obligado ya que tuve que liderar un equipo de desarrollo
        experto en ReactJS).
        <br />
        <br />
        Aprendí inicialmente viendo excelentes cursos en Udemy, luego leyendo la
        documentación (que la encuentro buenísima) y finalmente con la práctica
        laboral, fui reconociendo patrones, estándares y lo pude incorporar a
        mis conocimientos logrando crear proyectos, revisar código, pero de
        igual forma fui identificando malas prácticas, código espagueti,
        redundancias, etc. en los códigos que me tocaba revisar como lider de
        equipo.
        <br />
        <br />
        Claramente por mi parte fui aportando en la mejora continua e ir
        identificando y saldando deuda técnica asociada, implementar en el
        equipo los principios SOLID y Clean Code de manera de lograr un código
        semántico tal cual profesa ReactJS.
        <br />
        <br />
        Sigo aprendiendo, he notado que hay formas y tendencias distintas,
        algunas contradictorias entre si como: usar extensión jsx para los
        componentes v/s no usarla para ser consistente en las extensiones de
        JavaScript, usar o no usar Redux v/s Context API, Redux sigue vigente
        pero es para proyectos de gran escala, etc. En mi opinión personal, es
        mejor saber de cada uno de estos temas y ver la mejor forma de
        aplicarlo.
      </p>
    ),
  },
  {
    id: 9,
    icon: <SiMicrosoftsqlserver fill="#e60000" size="25" />,
    name: "SQL Server",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        Se trata del primer motor de base de datos que aprendí, ya que antes de
        ello trabajaba con bases de datos basadas en archivos (DBase III), desde
        su versión 6.5 a dinales del año 1996, hasta el día de hoy he logrado
        aprender no solo la sintáxis SQL (que me dio la base para otros
        motores), triggers, vistas y procedimientos almacenados, junto con ello
        transacciones, cursores, tablas temporales, assemblies, etc.
        <br />
        <br />
        Siempre ha sido un agrado trabajar con un motor tan robusto y estable,
        al punto que cuando he tenido que abordar proyectos en linux veo la
        posibilidad de que estos puedan contar con SQL Server como base de
        datos.
        <br />
        <br />
        Hoy en día la tendencia es hacia los productos open source lo que me ha
        alejado un poco de SQL Server, pero no por ello dejo de documentarme
        respecto a nuevas funcionalidades que ofrece.
      </p>
    ),
  },
  {
    id: 10,
    icon: <SiPostgresql fill="#39578f" size="25" />,
    name: "PostgreSQL",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        Es el motor de base de datos que más recientemente he utilizado, mi base
        en SQL Server me ayudó mucho a poder utilizarlo rápidamente y buscar las
        equivalencias.
        <br />
        <br />
        Considero que es un producto bastante bueno y robusto, me gustaría que
        tuviese un manager con el de SQL Server, pero últimamente conocí Hasana
        junto con GraphQL, por lo que pronto lo incorporaré dentro de mi stack
        de uso permanente.
      </p>
    ),
  },
  {
    id: 11,
    icon: <SiMysql fill="#18508b" size="25" />,
    name: "MySQL",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        Cuando programaba en PHP conocí MySQL (año 2004) y la herramienta
        PHPMyAdmin con la que se puede hacer todo con este motor siempre y
        cuando exista php ejecutandose en la máquina, claramente la herramienta
        cli es funcional pero más engorrosa en el uso.
        <br />
        <br />
        Más tarde conocí SQLyog que en su versión community ofrece las mismas
        características que el manager de SQL Server por lo que comencé a lograr
        los mismos resultados. MySQL incorpora los procedimientos almacenados
        desde su versión 5.x lo cual, en mi humilde opinión, terminó de
        consagrar el motor y desde esa instancia dependiendo de la plataforma
        del proyecto, podía ahora seleccionar qué base de datoas utilizar.
      </p>
    ),
  },
  {
    id: 12,
    icon: <SiMariadb fill="#bc724d" size="25" />,
    name: "MariaDB",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        En el año 2015 llegó a la empresa donde yo trabajaba un cliente con una
        necesidad de abordar un proyecto a gran escala pero con tecnología 100%
        opensource, en mi caso ofrecí utilizar MySQL como base de datos pero se
        me dijo que no debido a que el tipo de licenciamiento no era el
        apropiado.
        <br />
        <br />
        Investigando, llegué a que MySQL posee una licencia del tipo GPL que es
        gratis pero si la aplicación que se desarrolle es comercial, se debe
        pagar, así que conocí un fork llamado MariaDB (que hasta el día de hoy
        no encuentro diferencia a nivel funcional con MySQL) y pudimos abordar
        el proyecto con esta base de datos.
      </p>
    ),
  },
  {
    id: 13,
    icon: <SiFirebase fill="#f2d800" size="25" />,
    name: "Firebase",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        Si bien es cierto, Firebase es más que una base de datos, es un entorno
        de desarrollo provisto por Google que entre sus herramientas cuenta con
        Firestore como una base de datos a tiempo real, vine a conocerla a
        finales del año 2016, la encuentro bastante útil, es rápida, es segura y
        se acopla a aplicaciones frontend de manera casi nativa.
        <br />
        <br />
        Siento que aun puedo aprender más de este entorno, por ejemplo Auth y
        otros servicios de Google.
      </p>
    ),
  },
  {
    id: 14,
    icon: <SiSocketDotIo fill="#FFFFFF" size="25" />,
    name: "socket.io",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Ya conocía las ventajas de usar sockets cuando me tocaba desarrollar en
        c# (.net), ya que cuando es el servidor quien quiere informar de algo al
        cliente, son una muy buena alternativa. Luego aprendí los WebSockets
        para incorporarlos a la Web y hacer algunos chats o emitir algún mensaje
        o resultado de periféricos.
        <br />
        <br />
        Puntualmente la librería socket.io brinda una marco más sólido con menos
        lineas de programación y dos capas, una cliente y otra servidor listas
        para su uso con Javascript tanto para el frontend como para el backend
        en node.js.
        <br />
        <br />
        Pareciera que solo estoy definiendo la librería, pero es tal cual lo que
        ocupo de ella, obviamente debemos incorporar la comunicación a tiempo
        real, lo cual me permitió constuir los sistemas de monitoreo de equipos
        y sus periféricos que proveen al usuario una vista global de cómo está
        funcionando su implementación (entre otros muchos más usos).
      </p>
    ),
  },
  {
    id: 15,
    icon: <SiBootstrap fill="#9600f6" size="25" />,
    color: "#9600f6",
    name: "Bootstrap",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Aunque prefiero redactar mis propios estilos y con el tiempo
        prácticamente he construido un mini estándar, Bootstrap ha sido como una
        especie de mentor en ese sentido. Lo aprendí prácticamente por apuro ya
        que debía hacerme cargo de un proyecto cuyo frontend fue 100% hecho con
        esta librería, me gustó lo de las 12 columnas, la reutilización de
        componentes y su documentación es clara, de todas formas tomé algunos
        cursos en Udemy al respecto, esto fue a mediados del año 2015.
        <br />
        <br />
        Lo que más me gustó fue lo rápido que se aprende, lo estándar y
        estéticamente aceptable de los resultados a los que puede llegar un
        novato en el primer intento (esto último lo comento por que todos
        sabemos que un buen programador no es necesariamente un buen diseñador).
        <br />
        <br />
        Al día de hoy lo utilizo si se requiere lograr resultados rápidamente,
        no sin antes ver la posibilidad de ocupar algún material como los que
        existen por ejemplo para ReactJS.
      </p>
    ),
  },
  {
    id: 16,
    icon: <SiJquery fill="#311a99" size="25" />,
    name: "JQuery",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Sé lo que se dice de esta librería, que está obsoleta, que las
        especificaciones ES6 en adelante de JavaScript hacen innecesario su uso,
        etc. Pero para quienes vivimos el problema de la compatibilidad de
        navegadores respecto a funcionalidades de Javascript o manejo del DOM,
        JQuery fue un excelente aliado (me refiero en la etapa temprana de AJAX,
        HTML5 y CSS3).
        <br />
        <br />
        Fácil de implementar, fácil de aprender, efectos muy bonitos que le dan
        un toque profesional a la aplicación, acercandola a una del tipo desktop
        (sobre todo si se agrega JQuery UI) y asegura 100% de compatibilidad en
        navegadores (o casi el 100% en sus inicios).
        <br />
        <br />
        Lo aprendí mientras hacía mi primera maestría de c# (.net) en el año
        2009 ya que la misma página de Microsoft promocionaba el uso de JQuery,
        en aquella época programaba usando el Visual Studio 2008 y más adelante
        en su versión 2010 interpretaba código JavaScript (me refiero a que se
        podía debugear), fue un complemento ideal, un solo IDE que reconoce
        todos los lenguajes e incluso esta librería.
        <br />
        <br />
        Es efectivo que con el tiempo y las nuevas especificaciones de
        JavaScript dejé de lado JQuery, pero aun hay implementaciones que lo
        incorporan y normalmente requieren de mantención.
      </p>
    ),
  },
  {
    id: 17,
    icon: <SiDocker fill="#2d88d9" size="25" />,
    name: "Docker",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Utilizo esta tecnología desde aproximadamente el año 2019, al pricipio
        como usuario de imágenes pero hoy en día ya como creador utilizando
        DockerFiles y docker-compose, tengo cuenta en DockerHub y normalmente
        administro mis proyectos en producción de esta forma.
      </p>
    ),
  },
  {
    id: 18,
    icon: <SiElastic fill="#f0008c" size="25" />,
    name: "Elastic Stack",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Ante una necesidad de la empresa donde trabajaba de desarrollar
        rápidamente un método para monitorear nuestros equipos y servicios fue
        que conocí la Suite Elastic. Uno a uno fui aprendiendo sus componentes
        como filebeat, Metricbeat, Elasticsearch, Logstash y Kibana hasta montar
        desde cero un array de servidores con estos elementos instalados
        brindando servicios de monitoreo, esto fue en el año 2020.
        <br />
        <br />
        Desde esa fecha hasta el día de hoy hago uso de este stack, he aprendido
        cómo generar y parsear expresiones regulares con grok, preprocesadores y
        múltiples origenes de análisis.
      </p>
    ),
  },
  {
    id: 19,
    icon: <SiFigma fill="#fc5f4b" size="25" />,
    name: "Figma",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Al principio dibujaba mis prototipos en herramientas que no eran
        precisamente para ese uso, luego conocí Balsamiq Mockups, posteriormente
        Adobe XD, pero la herramienta definitiva fue Figma, su integración con
        plugins, el fácil uso, disponible desde la web, colaborativa, etc.
      </p>
    ),
  },
  {
    id: 20,
    icon: <SiGit fill="#ee3700" size="25" />,
    name: "Git",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Como comencé tempránamente a trabajar mientras estudiaba en la
        universidad, conocí bastantes softwares para control de versiones tales
        como CVS y SVN en Linux (luego cómo aplicarlos en Windows),
        posteriormente cuando programé en Visual Basic 6.0, en la empresa
        usabamos Visual Source Safe y posteriormente Team Foundation, pero una
        vez aprendí a usar Git, definitivamente pasó a ser de mi preferencia
        desde el año 2012.
      </p>
    ),
  },
  {
    id: 21,
    icon: <SiGithub fill="#FFFFFF" size="25" />,
    name: "GitHub",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Casi junto con aprender Git, conocí la plataforma de desarrollo
        colaborativo GitHub donde he ido dejando algunos de mis proyectos,
        aunque la gran mayoría han sido del tipo corporativo cuyos repositorios
        les pertenecen a las empresas dueñas de los proyectos.
        <br />
        <br />
        En mis labores diarias, me toca revisar PR, resolver conflictos,
        realizar merges, generar comentarios, etc. De igual forma como
        desarrollador pulls, push, commit, etc. Es definitivamente una
        herramienta de uso diario.
      </p>
    ),
  },
  {
    id: 22,
    icon: <SiGithubactions fill="#533afd" size="25" />,
    name: "GitHub Actions",
    type: "CI/CD",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Es una tecnología recientemente aprendida en el año 2020, como
        complementeo a GitHub, puntualmente he logrado configurar la ejecució
        automática de los test, cobertura, eslint levantando imágenes desde
        DockerHub.
      </p>
    ),
  },
  {
    id: 23,
    icon: <SiCircleci fill="#FFFFFF" size="25" />,
    name: "CircleCI",
    type: "CI/CD",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Es una tecnología recientemente aprendida en el año 2020, como
        complementeo a GitHub, puntualmente he logrado configurar la ejecución
        automática de los test y cobertura.
      </p>
    ),
  },
  {
    id: 24,
    icon: <SiWindows fill="#FFFFFF" size="25" />,
    name: "Windows",
    type: "so",
    side: "so",
    learning: false,
    detail: (
      <p>
        Ha sido hasta el año 2020 sistema operativo de uso diario, lo utilizo
        desde su versión 3.1 (luego 3.11), pasando por las versiones 95, 98, NT,
        2000, XP, 7, 8 (8.1) y 10
        <br />
        <br />
        Adicionalmente he tenido que configurar Windows Server para dejar
        operativos equipos y servicios como Web, base de datos y FTP junto con
        sus roles y seguridad.
      </p>
    ),
  },
  {
    id: 25,
    icon: <SiLinux fill="#FFFFFF" size="25" />,
    name: "Linux",
    type: "so",
    side: "so",
    learning: false,
    detail: (
      <p>
        Siempre fue el sistema operativo donde desplegaba los proyectos, pero me
        propuse en el año 2020 usarlo como el sistema operativo de uso diario,
        esto me obligó a mejorar mis conocimientos e incluso mejorar los tiempos
        entre desarrollo y despliegue.
        <br />
        <br />
        Tengo como meta lograr alguna certificación como LPI Essentials, pero en
        el intertando en el uso diario, ya manejo comandos, seguridad,
        levantamiento de servicios desde cero a productivo como dominios,
        reverse proxy, web, correo, etc.
      </p>
    ),
  },
  {
    id: 26,
    icon: <SiRaspberrypi fill="#bd004a" size="25" />,
    name: "Raspberry",
    type: "so",
    side: "so",
    learning: false,
    detail: (
      <p>
        Desde que compré la primera de estas vía Aliexpress y pude apreciar que
        todo lo que se podía hacer con ella, me interesó saber más de ellas, el
        sistema operativo al estar basado en Debian me fui muy intuitivo y
        sencillo de ocupar (por mi experiencia en Linux).
        <br />
        <br />
        Laboralmente hablando logre hacerlas interactuar con pantallas táctiles
        de 22" instaladas en tótems de autoatención para sistemas de filas.
        Posteriormente para un MVP de equipos de parking (estacionamiento), pude
        hacerlas interactuar con POS de Transbank para pago abaratando muchos
        los costos en equipamiento.
        <br />
        <br />
        En proyectos personales, las he hecho trabajar para despliegue de
        publicidad en pantallas LCD, domótica en casas y algunos protoripos de
        reconocimiento reconicimiento con OpenCV.
      </p>
    ),
  },
  {
    id: 27,
    icon: <SiReact fill="#5ccef8" size="25" />,
    name: "React Native",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 28,
    icon: <SiNextDotJs fill="#ffffff" size="25" />,
    name: "Next.js",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 29,
    icon: <SiPython fill="#3e5c97" size="25" />,
    name: "Python",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 30,
    icon: <SiFlask fill="#ffffff" size="25" />,
    name: "Flask",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 31,
    icon: <SiDjango fill="#00412e" size="25" />,
    name: "Django",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 32,
    icon: <SiVueDotJs fill="#00c281" size="25" />,
    name: "Vue.js",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 33,
    icon: <SiNuxtDotJs fill="#43806e" size="25" />,
    name: "Nuxt",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 34,
    icon: <SiVuetify fill="#4569f4" size="25" />,
    name: "Vuetify",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 35,
    icon: <SiMongodb fill="#18a442" size="25" />,
    name: "MongoDB",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 36,
    icon: <SiGraphql fill="#e900a3" size="25" />,
    name: "GraphQL",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 37,
    icon: <SiApollographql fill="#FFFFFF" size="25" />,
    name: "Apollo",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 38,
    icon: <SiHeroku fill="#560096" size="25" />,
    name: "Heroku",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 39,
    icon: <SiKubernetes fill="#522ce0" size="25" />,
    name: "Kubernetes",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 40,
    icon: <SiGitlab fill="#e02400" size="25" />,
    name: "GitLab",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 41,
    icon: <SiTypescript fill="#4751c5" size="25" />,
    name: "Typescript",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 42,
    icon: <SiSass fill="#c63b8c" size="25" />,
    name: "Sass",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 43,
    icon: <SiJest fill="#c42800" size="25" />,
    name: "Jest",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Si bien es cierto, las pruebas unitarias, funcionales y de integración
        prefiero hacerlas con el método QA (automatizadas con Selenium y/o
        manuales), con la experiencia de uso comprendí que también es bueno
        programar (codificar) los test
        <br />
        <br />
        Los cursos de ReactJS a veces incorporan algunos capítulos de testing
        con Jest (son los menos), pero en mi caso tuve la experiencia de
        trabajar en un lugar donde la codificación de los test era obligatoria y
        en muchos casos significaba más tiempo que el desarrollo del feature en
        sí.
        <br />
        <br />
        Esta experiencia me llevó a aprender más en detalle temas como mocks,
        asincronía, storybooks, snapshots, etc. La documentación oficial es muy
        buena, pero la práctica es imprescindible.
      </p>
    ),
  },
  {
    id: 44,
    icon: <SiWebpack fill="#3856bf" size="25" />,
    name: "Webpack",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Lo utilizo hace aproximadamente 4 años, aprendí mecánicamente a usarlo,
        siento que es una herramienta indispensable hoy en día, pero que podría
        mejorar su rendimiento. Los framework como ReacJS lo incorporan dentro
        de su cli, por ello no me he visto en la necesidad de profundizar en su
        entendimiento.
        <br />
        <br />
        (*) Ultimamente he estado revisando Snowpack
      </p>
    ),
  },
  {
    id: 45,
    icon: <IoLogoPwa fill="#570fc2" size="25" />,
    name: "PWA",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Si bien es cierto, no es una tecnología sino más bien la aplicación se
        una serie de tecnologías de tal forma que una aplicación web pueda
        funcionar como si fuese una app nativa en dispositivos móviles de
        preferencia, ha sido para mi una excelente alternativa para distribuir
        las aplicaciones que me ha tocado construir hacia clientes móviles
        aprovechando las posibilidades que PWA provee tales como estratégias de
        almacenamiento en caché, utilización de ciertos periféricos,
        notificaciones push, trabajo sin conexión, etc. (mientras me perfecciono
        en desarrollo móvil nativo)
        <br />
        <br />
        Aprendí su uso en el año 2018 e intento aplicar todas sus
        consideraciones en cada proyecto que he abordado desde esa fecha.
      </p>
    ),
  },
  {
    id: 46,
    icon: <SiJson fill="#3d3d3d" size="25" />,
    name: "JSON",
    type: "library",
    side: "frontend",
    learning: false,
    detail: (
      <p>
        Como formato de intercambio de datos lo encuentro muy bueno, lo aplico
        desde que lo aprendí (año 2010 aproximadamente) y hoy en día es
        imprescindible para interactuar con las nuevas tecnologías partiendo por
        el protocolo REST.
      </p>
    ),
  },
  {
    id: 47,
    icon: <SiJson fill="#3d3d3d" size="25" />,
    name: "REST",
    type: "library",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Supe de su existencia aproximadamente en el año 2010, en aquella época
        era muy difícil aplicarlo sobretodo en .NET (c#) y php. Luego los
        tiempos cambiaron, afloraron nuevas tecnologías (o evolucionaron las
        tecnologías existentes) y comencé primero con el framework Slim de PHP
        con excelentes resultados, posteriormente ,NET sacó nuevas versiones de
        su framework y comenzó a incorporar REST, finalmente aprendí node.js,
        junto con ello Express. Todo desarrollo previamente realizado con Web
        Services comencé a migrarlo a este protocolo, ya podía aplicarlo a PHP,
        .NET y node.js, me quedé con esta última tecnología y el resto es
        historia.
      </p>
    ),
  },
  {
    id: 48,
    icon: <FiSettings fill="#FFFFFF" size="25" />,
    name: "WebServices",
    type: "library",
    side: "backend",
    learning: false,
    detail: (
      <p>
        Aprendí a construir servicios web aproximadamente en el año 2007, fue un
        buen complemento para los proyectos que estaba llevando ya que podía
        integrarlos a otros sistemas, obtener información de fuentes externas o
        interactuar con ellos mediante su consumo.
        <br />
        <br />
        Me permitió desacoplar capas en los sistemas como por ejemplo la base de
        datos y poner sobre ella servicios web con seguridad mediante contratos,
        asegurando de esta forma la interacción controlada sobre ella.
        <br />
        <br />
        Posteriormente logré intercalar su uso con AJAX desde el frontend
        pudiendo hacer aplicaciones más dinámicas.
        <br />
        <br />
        Si bien es cierto, he construido servicios web en otros lenguajes como
        Java o PHP (usando NuSOAP), mi experiencia más concreta ha sido con .NET
        (c#).
        <br />
        <br />
        Ultimamente he aplicado este conocimiento en proyectos de integración ya
        que aun se utiliza.
      </p>
    ),
  },
  {
    id: 49,
    icon: <SiSvelte fill="#f73c00" size="25" />,
    name: "Svelte",
    type: "learning",
    side: "learning",
    learning: true,
  },
  {
    id: 50,
    icon: <SiNginx fill="#009400" size="25" />,
    name: "NGINX",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Lo comencé a utilizar como alternativa a Apache, pero definitivamente me
        gustó mucho más, aprendí no solo a configurarlo para alojamiento de
        desarrollos web, también como reverse proxy, alojar certificacos SSL
        para HTTPS, etc.
        <br />
        <br />
        Lo ocupo prácitamente a diario como parte de mi stack de uso frecuente.
      </p>
    ),
  },
  {
    id: 51,
    icon: <SiApache fill="#c31f35" size="25" />,
    name: "Apache",
    type: "tool",
    side: "tool",
    learning: false,
    detail: (
      <p>
        Cuando desarrollaba con PHP en entornos linux, aprendí a instalarlo y
        configurarlo, la utilidad que me prestó fue mayormente alojar los
        desarrollos web en entornos linux o posteriormente con WAMP para
        Windows.
      </p>
    ),
  },
  {
    id: 52,
    icon: <SiApache fill="#1685ce" size="25" />,
    name: "SQLite",
    type: "db",
    side: "db",
    learning: false,
    detail: (
      <p>
        Muy útil como mini motor de base de datos, la he ocupado para pequeñas
        aplicaciones y como repositorio de log, me impresiona sus capacidades y
        lo simple que es incorporarla a una aplicación.
      </p>
    ),
  },
  {
    id: 53,
    icon: <SiGatsby fill="#633194" size="25" />,
    name: "Gatsby",
    type: "learning",
    side: "learning",
    learning: true,
  },
];

export default jsonTechnology;
