const jsonCertifications = [
  {
    range: {
      from: "Nov",
      to: "2020",
    },
    position: "Scrum Master Professional Certificate (SMPC®)",
    company: "CertiProf",
  },
  {
    range: {
      from: "Nov",
      to: "2020",
    },
    position: "Scrum Product Owner Professional Certificate (SMPC®)",
    company: "CertiProf",
  },
  {
    range: {
      from: "Nov",
      to: "2020",
    },
    position: "Scrum Foundation Professional Certificate (SMPC®)",
    company: "CertiProf",
  },
];

export default jsonCertifications;
