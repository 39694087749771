const HamButton = ({ setDespliega, despliega }) => {
  const handleClick = () => {
    setDespliega(despliega === 1 ? 2 : 1);
  };
  return (
    <div className="ham-button">
      <button onClick={handleClick}>
        <i className="fas fa-bars"></i>
      </button>
    </div>
  );
};

export default HamButton;
