const jsonSide = [
  { id: 1, type: "frontend", name: "FrontEnd", icon: "fas fa-desktop" },
  { id: 2, type: "backend", name: "BackEnd", icon: "fas fa-server" },
  { id: 3, type: "db", name: "Bases de Datos", icon: "fas fa-database" },
  { id: 4, type: "tool", name: "Herramientas", icon: "fas fa-tools" },
  {
    id: 5,
    type: "so",
    name: "Sistemas Operativos",
    icon: "fas fa-laptop-code",
  },
  {
    id: 6,
    type: "learning",
    name: "Aprendizaje",
    icon: "fas fa-graduation-cap",
  },
];

export default jsonSide;
