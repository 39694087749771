import AboutStackList from "./AboutStackList";
import jsonSide from "../../../../data/side.js";
import "../../../../styles/stack.scss";

const StackType = ({ setStackItemData, openStackModal, type }) => {
  let { name, icon } = jsonSide.filter((item) => item.type === type)[0];
  return (
    <div className="stack-type">
      <div className="stack-type-title">
        <i className={icon} onClick={() => openStackModal}></i>
        <span>{name}</span>
      </div>
      <div className="stack-type-detail">
        <AboutStackList
          setStackItemData={setStackItemData}
          openStackModal={openStackModal}
          side={type}
        />
      </div>
    </div>
  );
};

export default StackType;
