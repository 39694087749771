//import { useModal } from "../../../../hooks/useModal";
//import Modal from "../About/StackModal";
import "../../../../styles/stack-item.scss";

const StackItem = ({ setStackItemData, openStackModal, item }) => {
  let { icon, id, name } = item;
  const handleOpenModal = () => {
    setStackItemData(item);
    openStackModal();
  };
  return (
    <>
      <div
        className={`stack-item ${setStackItemData && "stack-item-select"}`}
        key={id}
        onClick={setStackItemData ? handleOpenModal : null}
      >
        {icon}
        <span>{name}</span>
      </div>
    </>
  );
};

export default StackItem;
