const importAll = (require) => {
  const cache = {};
  let images = [];
  require.keys().forEach((key) => (cache[key] = require(key)));
  Object.entries(cache).map((module) =>
    images.push(<img src={module[1].default} alt="" />)
  );
  return images;
};

const jsonProjects = [
  {
    id: 1,
    title: "Gestión de Fábrica, Distribución y Venta",
    description: (
      <p>
        Sistema que administra la gestión del ciclo de vida de los productos de
        una pastelería, desde la programación hasta la entrega del producto al
        cliente, pasando por la fabricación, etiquetado, despacho, recepción y
        venta junto con la emisión de documentos tributarios. Dentro de las
        funcionalidades está la gestión de los pedidos desde sucursales, manejo
        de stock, precios y publicidad en pantallas ubicadas en los locales.
      </p>
    ),
    features: (
      <ul>
        <li>Registro detallado de las etapas en la línea de producción</li>
        <li>Generación y escaneo de códigos QR</li>
        <li>Dashboards informativos a tiempo real de producción en fábrica</li>
        <li>Emisión de documentos tributarios electrónicos</li>
        <li>Gestión de pedidos desde locales</li>
        <li>Pago en linea para retiro en tienda</li>
        <li>
          Actualización de contenidos para pantallas de publicidad y página web
        </li>
        <li>Despliegue de publicidad en locales</li>
        <li>Integración con POS Transbank en locales</li>
        <li>Integración con WebPay</li>
        <li>API de integración para terceros</li>
        <li>Monitoreo de periféricos y estaciones de trabajo</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>Desktop (personal de la fábrica)</li>
        <li>Mobile (personal en locales)</li>
        <li>Pantallas LCD (publicidad locales y Dashboards de producción)</li>
      </ul>
    ),
    customer: "Pastelería El Parrón",
    stack: [3, 4, 1, 14, 45, 2, 10, 25],
    images: importAll(
      require.context("../images/projects/001", false, /\.(png|jpe?g|svg)$/)
    ),
  },
  {
    id: 2,
    title: "Pago de Cuentas",
    description: (
      <p>
        Sistema de autoatención cuya finalidad es que el cliente pueda realizar
        el pago de su cuenta de electricidad escaneando su boleta para obtener
        el monto a cancelar y luego utilizar el dispositivo de pago dispuesto
        por Transbank.
      </p>
    ),
    features: (
      <ul>
        <li>Interfaz intuitiva y diseñada para pantalla táctil</li>
        <li>
          Estaneo del código QR de la boleta para despliegue de monto a pagar
        </li>
        <li>Orientación al usuario mediante animaciones</li>
        <li>Activación de los perifétricos conforme se requieran utilizar</li>
        <li>Pago desde el mismo tótem mediante POS Transbank</li>
        <li>Monitoreo de tótems y sus periféricos</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>
          Tótem de autoatención con pantalla táctil, lector QR, impresora
          térmica y dispositivo POS Transbank
        </li>
        <li>Desktop para administración y monitoreo</li>
      </ul>
    ),
    customer: "Chilquinta",
    stack: [3, 4, 1, 14, 2, 5, 12, 24],
    images: importAll(
      require.context("../images/projects/002", false, /\.(png|jpe?g|svg)$/)
    ),
  },
  {
    id: 3,
    title: "Sistema de gestión documental",
    description: (
      <p>
        Sistema que gestiona el flujo de aprobación de los distintos tipos de
        documentos de mayor frecuencia en la empresa tales como solicitudes de
        vacaciones, aprobación de gastos, solicitud de inversión, etc. El flujo
        es configurado por cada documento y va solicitando el visado a cada
        autorizador ya sea por su cargo o su nombre, considerando reemplazos,
        escalamientos, etc. Adicionalmente provee funcionalidades de búsqueda,
        despliegue, envío y la posibilidad de adjuntar otros documentos.
      </p>
    ),
    features: (
      <ul>
        <li>Generación dinámica de flujos de aprobación por documento</li>
        <li>Posibilidad de inicorporar nuevos tipos de documentos</li>
        <li>Asignación de aprobadores en paralelos o excluyentes</li>
        <li>
          Configuración de reemplazos o escalamientos en caso de no respuesta
        </li>
        <li>Monitoreo de tótems y sus periféricos</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>Interfaz web responsiva</li>
      </ul>
    ),
    customer: "ThyssenKrupp Elevadores",
    stack: [3, 4, 1, 5, 12, 24],
    images: importAll(
      require.context("../images/projects/003", false, /\.(png|jpe?g|svg)$/)
    ),
  },
  {
    id: 4,
    title: "Pago y orientación de Servicios Municipales",
    description: (
      <p>
        Sistema de apoyo al contribuyente para que pueda realizar el pago de los
        sevicios municipales tales como permiso de circulación, patente
        comercial y multas de tránsito. Puede además solictar información de
        departamentos y trámites que se pueden realizar en ellos junto con el
        mapa de orientación. El software posee una interfaz intuitiva y diseñada
        para pantalla táctil, orientación mediante animaciones y activación de
        los perifétricos conforme se requieran utilizar, puntualmente lector de
        códigos QR, impresora térmica y dispositivo Transbank para pago con
        tarjeta de crédito/débito.
      </p>
    ),
    features: (
      <ul>
        <li>Búsqueda intuitiva por departamento o trámite municipal</li>
        <li>Despliegue de información del trámite y sus requisitos</li>
        <li>Despliegue de mapa de ubicación con animación cómo llegar</li>
        <li>Mapa y animación dinámica dependiendo de la ubicación del tótem</li>
        <li>
          Consulta de deuda en trámites como Permiso de Circulación, Multas o
          Patente Comercial
        </li>
        <li>Orientación al usuario mediante animaciones</li>
        <li>Activación de los perifétricos conforme se requieran utilizar</li>
        <li>Pago del trámite desde el mismo tótem mediante POS Transbank</li>
        <li>Monitoreo de tótems y sus periféricos</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>
          Tótem de autoatención con pantalla táctil, impresora térmica y
          dispositivo POS Transbank
        </li>
        <li>Desktop para administración y monitoreo</li>
      </ul>
    ),
    customer: "Municipalidad de Vitacura",
    stack: [3, 4, 1, 5, 12, 24],
    images: importAll(
      require.context("../images/projects/004", false, /\.(png|jpe?g|svg)$/)
    ),
  },
  {
    id: 5,
    title: "Sistema de Filas",
    description: (
      <p>
        Software de gestión de atención montado en tótems de cara a los clientes
        quienes pueden visualizar su turno mediante pantallas LCD, quienes son
        llamados desde los puntos de atención cuyo flujo es gestionado
        centralmente desde un panel de adminitración junto a las estadísticas.
      </p>
    ),
    features: (
      <ul>
        <li>Interfaz intuitiva y diseñada para pantalla táctil</li>
        <li>
          Emisión de tiket de atención junto con estimación de tiempo de espera
        </li>
        <li>
          Gestión de tótems v/s módulos de atención v/s LCD de visualización de
          turnos
        </li>
        <li>Gestión de horarios de atención</li>
        <li>Atención fuera de fila</li>
        <li>Derivación a otros puntos de atención conservando turno</li>
        <li>Estadísticas y Dashboard a tiempo real</li>
        <li>Monitoreo de tótems y sus periféricos</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>Tótem de autoatención con pantalla táctil, e impresora térmica</li>
        <li>Desktop para llamado de turnos desde mesón de atención</li>
        <li>
          LCD para visualización de turno y módulo de atención junto con
          publicidad
        </li>
        <li>Desktop para gestión de fila, puntos de atención, totems, etc.</li>
        <li>Desktop para administración y monitoreo</li>
      </ul>
    ),
    customer: "Clínica Dávila",
    stack: [3, 4, 1, 14, 2, 9, 25],
    images: importAll(
      require.context("../images/projects/005", false, /\.(png|jpe?g|svg)$/)
    ),
  },
  {
    id: 6,
    title: "Autoatención",
    description: (
      <p>
        Sistema mediante el cual un cliente puede realizar diversas consultas
        respecto a su afilización tales como estados de cuenta, certificados,
        informes, etc. y además comunicarse por voz con una ejecutiva utilizando
        telefonía IP, la cual además puede enviarle información que podrá ser
        impresa en papel a tiempo real (como por ejemplo un contrato). El
        software posee una interfaz intuitiva y diseñada para pantalla táctil,
        orientación mediante animaciones y activación de los perifétricos
        conforme se requieran utilizar, puntualmente auticular para llamada IP e
        impresora térmica.
      </p>
    ),
    features: (
      <ul>
        <li>Interfaz intuitiva y diseñada para pantalla táctil</li>
        <li>Búsqueda y obtención de información sencilla y rápida</li>
        <li>Impresión de cartolas y comprobantes</li>
        <li>
          Posibilidad de comunicación por voz mediante telefonía IP por
          auricular físico
        </li>
        <li>Monitoreo de tótems y sus periféricos</li>
      </ul>
    ),
    interfaces: (
      <ul>
        <li>
          Tótem de autoatención con pantalla táctil, impresora térmica y
          auricular para llamada por voz
        </li>
        <li>Desktop para administración y monitoreo</li>
      </ul>
    ),
    customer: "Coopeuch",
    stack: [3, 4, 1, 14, 5, 12, 24],
    images: importAll(
      require.context("../images/projects/006", false, /\.(png|jpe?g|svg)$/)
    ),
  },
];

export default jsonProjects;
