const ResumeItem = ({ item }) => {
  return (
    <div className="rs-item">
      <div className="rs-range">
        <div className="rs-range-item">{item.range.to}</div>
        <div className="rs-range-item">{item.range.from}</div>
      </div>
      <div className="rs-circle">
        <div className="rs-center-circle">
          <div className="rs-dot"></div>
        </div>
        <div className="rs-line-left"></div>
        <div className="rs-line-right"></div>
      </div>
      <div className="rs-job">
        <div className="rs-company">{item.company}</div>
        <div className="rs-position">{item.position}</div>
        <div className="rs-tasks">{item.tasks}</div>
      </div>
    </div>
  );
};

export default ResumeItem;
