import AppRouter from "./routers/AppRouter";
//import Resume from "./components/portfolio/Content/Resume/Resume";
import "./styles/app.scss";

function App() {
  //return <Resume />;
  return <AppRouter />;
}

export default App;
