import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "./About/About";
import Projects from "./Projects/Projects";
import Resume from "./Resume/Resume";
import Contact from "./Contact/Contact";
import "../../../styles/content.scss";

function Content({ setDespliega }) {
  return (
    <div className="content scroll" onClick={() => setDespliega(2)}>
      <Switch>
        <Route path="/" exact component={About} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/resume" exact component={Resume} />
        <Route path="/contact" exact component={Contact} />
      </Switch>
    </div>
  );
}

export default Content;
