import StackItem from "../Stack/StackItem";
import jsonTechnology from "../../../../data/stack.js";

const ProjectItem = ({ item }) => {
  const { title, description, features, interfaces, stack } = item;
  return (
    <div className="section">
      <div className="sub-title">{title}</div>
      <h3>Descripción</h3>
      {description}
      <h3>Características</h3>
      {features}
      <h3>Interfaces</h3>
      {interfaces}
      <h3>Tecnologías</h3>
      <div className="project-stack">
        {stack.map((stackId) =>
          jsonTechnology
            .filter((item) => item.id === stackId)
            .map((item) => <StackItem key={item.id} item={item} />)
        )}
      </div>
    </div>
  );
};

export default ProjectItem;
