import React from "react";
import Language from "./Language";
import Menu from "./Menu";
import Photo from "./Photo";
import HamButton from "./HamButton";
import "../../../styles/nav.scss";

const Nav = ({ despliega, setDespliega }) => {
  return (
    <div className="nav">
      <Photo />
      <Menu despliega={despliega} setDespliega={setDespliega} />
      <Language />
      <HamButton setDespliega={setDespliega} despliega={despliega} />
    </div>
  );
};

export default Nav;
