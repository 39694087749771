import "../../../../styles/modal.scss";

const AboutStackModal = ({ children, isOpenStackModal, closeStackModal }) => {
  return (
    <div
      className={`modal ${isOpenStackModal && "is-open"}`}
      onClick={closeStackModal}
    >
      <div className="modal-container animate__animated animate__fadeIn animate__faster">
        <button className="modal-close" onClick={closeStackModal}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default AboutStackModal;
