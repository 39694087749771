import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/photo.scss";
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import photo from "../../../images/cmatus.jpg";

const Photo = () => {
  return (
    <div className="photo">
      <div className="name">Claudio Matus Alegría</div>
      <div className="image">
        <img src={photo} alt="" />
      </div>
      <div className="mini-description">
        Hola, soy ingeniero en software senior y te doy la bienvenida a mi
        portafolio
      </div>
      <div className="network">
        <div className="network-item">
          <a
            href="https://www.linkedin.com/in/claudiomatus/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
        <Link to="/contact">
          <div className="network-item">
            <FaEnvelope />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Photo;
