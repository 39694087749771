const jsonJobs = [
  {
    range: {
      from: "2020",
      to: "Hoy",
    },
    company: "EquipCollaboration",
    position: "Gerente de Desarrollo",
    tasks:
      "Liderazgo y gestión del equipo de desarrollo, participación activa en el ciclo de vida del requerimiento, desde las especificaciones hasta la puesta en producción, pasando por la revisión de código, actualización de repositorios GitHub, generación de imágenes en DockerHub, actualización de servidores y distribución de cada uno de los features que diariamente se construyen.",
  },
  {
    range: {
      from: "2019",
      to: "2020",
    },
    company: "e-certchile S.A.",
    position: "Sub Gerente de Desarrollo y PMO",
    tasks:
      "Liderazgo del equipo de desarrollo, participación activa en el levantamiento y gestión de los proyectos en curso con clientes como Crediautos, Banco de Chile y Mutual entre otros. Actualmente focalizado al área de preventa para la elaboración y comercialización de los productos internos de servicio de valor agregado a la firma electrónica.",
  },
  {
    range: {
      from: "2017",
      to: "2019",
    },
    company: "Vigatec S.A.",
    position: "Jefe de Proyectos",
    tasks:
      "Gestión y levantamiento de proyectos de equipos de auto-atención para: emisión de documentos, pago de cuentas, trámites municipales, ubicación (planos), gestores de filas y pago en efectivo para los diversos clientes pertenecientes al área gubernamental pública (Banco Estado, Mideplan, Fonasa, Registro Civil, Segpres, etc.), municipal (Vitacura, Concepción, etc.) y privada (Cencosud, Agrosuper, etc.). Gestión del proceso completo desde la pre-venta y apoyo técnico al área comercial.",
  },
  {
    range: {
      from: "2015",
      to: "2017",
    },
    company: "DTS Ltda.",
    position: "Jefe de Proyectos",
    tasks:
      "Gestión y levantamiento de proyectos para la implementación en sucursales de equipos de auto-atención, manejo de filas, telefonía IP, interacción Transbank y validación biométrica por cédula de identidad y huella digital para clientes con presencia a lo largo del país tales como MetLife, Fashion’s Park, VTR, Clínica Meds, Coopeuch, etc. Amplia participación en construcción a nivel de software, interacción con dispositivos de hardware y pruebas de funcionamiento.",
  },
  {
    range: {
      from: "2008",
      to: "2015",
    },
    company: "ThyssenKrupp Elevadores S.A.",
    position: "Jefe de Desarrollo",
    tasks:
      "Liderazgo del equipo de para la región a cargo de 9 ingenieros en desarrollo distribuidos en Argentina, Perú, Chile y Colombia abordando proyectos informáticos en Latinoamerica de manera presencial y/o remota. Gestión, análisis, diseño y desarrollo intranet corporativa que incluye sistemas de gestión documental, RR.HH., seguridad y accidentes, manejo de perfiles y control de acceso, soporte e implementación ERP gestión interna de procesos, sistema de facturación electrónica para sucursal de Argentina, especificaciones para futuros desarrollos en Web, frameworks front-end y back-end, implementación y capacitación de soluciones de software en 14 países.",
  },
  {
    range: {
      from: "2002",
      to: "2007",
    },
    company: "Agencia de Aduanas Stein S.A.",
    position: "Ingeniero de Sistemas",
    tasks:
      "Análisis, diseño, construcción y arquitectura de sistemas para clientes de la empresa (comercio exterior) en plataforma Web e integración directa o servicios a sus aplicaciones. Levantamientos comerciales, seguimiento de proyectos y asesoría tecnológica al cliente final y adquisiciones internas de equipamiento (software e implementación). Levantamiento, análisis, diseño y desarrollo sistemas de interacción con la aduana. Construcción de sistemas para registro de documentos aduaneros y tributarios, cálculo de costos, contenedores, corrección monetaria e informes a clientes: Philips, Nike, Falabella, 3M, JohnsonDiversey, Prodalam y otros. Desarrollo de aplicaciones internas de transferencia de archivos Comex, digitación y digitalización de documentos.",
  },
];

export default jsonJobs;
